'use client';
import style from './not-found.module.sass';
import Link from 'next/link';
export const dynamic = 'force-dynamic';
export default function Error({
	error,
	reset
}: {
	error: Error;
	reset: () => void;
}) {

	return (
		<div className={style.ErrorCont}>
			<div className={style.WaitNews}>{'Ждём новостей :)'}</div>
			<div className={style.Publick}>В этой рубрике пока нет новостей</div>
			<Link href={'/'}>
				<button className={style.BtnAtHome}>На главную</button>
			</Link>
		</div>
	);
}
